export const theme = {
	palette: {
		primary: {
			light: "#e5d5b3",
			main: "#b38e42",
			dark: "#836422"
		},
		secondary: {
			light: "#f55464",
			main: "#f00017",
			dark: "#aa0010"
		},
		background: {
			default: "#f5f5f5",
			header: "#ffffff",
			menu: "#d3d1cf",
			footer: "#b6b2af",
			light: "#ffffff",
			dark: "#504c49"
		},
		text: {
			primary: "#333333",
			secondary: "#666666",
			disabled: "#bfbfbf",
			hint: "#bfbfbf"
		},
		grey: {
			50: "#f8f7f7",
			100: "#e7e5e4",
			200: "#d3d1cf",
			300: "#b6b2af",
			400: "#9e9894",
			500: "#867e79",
			600: "#6b6561",
			700: "#504c49",
			800: "#363330",
			900: "#1b1918",
			A100: "#d3d1cf",
			A200: "#b6b2af",
			A400: "#363330",
			A700: "#6b6561"
		},
		common: {
			white: "#ffffff",
			black: "#000000"
		},
		info: {
			light: "#77b6da",
			main: "#0175b9",
			dark: "#01446c"
		},
		success: {
			light: "#a4bf7a",
			main: "#70992e",
			dark: "#477301"
		},
		warning: {
			light: "#ffde77",
			main: "#ffc105",
			dark: "#d19e05"
		},
		error: {
			light: "#f08080",
			main: "#d50b0b",
			dark: "#8b0101"
		},
		divider: "#cccccc",
		shape: {
			borderRadius: ""
		}
	},
	typography: {
		fontFamily: "Open Sans",
		"display-large": {
			fontFamily: "Sofia Sans",
			fontWeight: 400,
			fontSize: 56,
			letterSpacing: -0.56,
			lineHeight: "62px"
		},
		"display-medium": {
			fontFamily: "Sofia Sans",
			fontWeight: 400,
			fontSize: 48,
			letterSpacing: 0,
			lineHeight: "53px"
		},
		"display-small": {
			fontFamily: "Sofia Sans",
			fontWeight: 400,
			fontSize: 42,
			letterSpacing: 0,
			lineHeight: "47px"
		},
		"headline-large": {
			fontFamily: "Sofia Sans",
			fontWeight: 400,
			fontSize: 36,
			letterSpacing: 0,
			lineHeight: "40px"
		},
		"headline-medium": {
			fontFamily: "Sofia Sans",
			fontWeight: 400,
			fontSize: 28,
			letterSpacing: 0,
			lineHeight: "31px"
		},
		"headline-small": {
			fontFamily: "Sofia Sans",
			fontWeight: 400,
			fontSize: 24,
			letterSpacing: 0,
			lineHeight: "27px"
		},
		"title-large": {
			fontFamily: "Roboto Slab",
			fontWeight: 400,
			fontSize: 24,
			letterSpacing: 0,
			lineHeight: "39px"
		},
		"title-medium": {
			fontFamily: "Roboto Slab",
			fontWeight: 400,
			fontSize: 20,
			letterSpacing: 0,
			lineHeight: "32px"
		},
		"title-small": {
			fontFamily: "Roboto Slab",
			fontWeight: 400,
			fontSize: 18,
			letterSpacing: 0.18,
			lineHeight: "29px"
		},
		"body-large": {
			fontFamily: "Open Sans",
			fontWeight: 400,
			fontSize: 20,
			letterSpacing: 0.2,
			lineHeight: "32px"
		},
		"body-medium": {
			fontFamily: "Open Sans",
			fontWeight: 400,
			fontSize: 18,
			letterSpacing: 0.18,
			lineHeight: "29px"
		},
		"body-small": {
			fontFamily: "Open Sans",
			fontWeight: 400,
			fontSize: 14,
			letterSpacing: 0.7000000000000001,
			lineHeight: "23px"
		},
		"label-large": {
			fontFamily: "Sofia Sans",
			fontWeight: 600,
			fontSize: 20,
			letterSpacing: 0.2,
			textTransform: "uppercase",
			lineHeight: "20px"
		},
		"label-medium": {
			fontFamily: "Sofia Sans",
			fontWeight: 600,
			fontSize: 16,
			letterSpacing: 0.16,
			textTransform: "uppercase",
			lineHeight: "16px"
		},
		"label-small": {
			fontFamily: "Sofia Sans",
			fontWeight: 600,
			fontSize: 14,
			letterSpacing: 0.42,
			textTransform: "uppercase",
			lineHeight: "14px"
		},
		h1: {
			fontFamily: "Sofia Sans",
			fontWeight: 400,
			fontSize: 56,
			letterSpacing: -0.56,
			lineHeight: "62px"
		},
		h2: {
			fontFamily: "Sofia Sans",
			fontWeight: 400,
			fontSize: 48,
			letterSpacing: 0,
			lineHeight: "53px"
		},
		h3: {
			fontFamily: "Sofia Sans",
			fontWeight: 400,
			fontSize: 42,
			letterSpacing: 0,
			lineHeight: "47px"
		},
		h4: {
			fontFamily: "Sofia Sans",
			fontWeight: 400,
			fontSize: 36,
			letterSpacing: 0,
			lineHeight: "40px"
		},
		h5: {
			fontFamily: "Sofia Sans",
			fontWeight: 400,
			fontSize: 28,
			letterSpacing: 0,
			lineHeight: "31px"
		},
		h6: {
			fontFamily: "Sofia Sans",
			fontWeight: 400,
			fontSize: 24,
			letterSpacing: 0,
			lineHeight: "27px"
		},
		subtitle1: {
			fontFamily: "Open Sans",
			fontWeight: 400,
			fontSize: 20,
			letterSpacing: 0.2,
			lineHeight: "32px"
		},
		subtitle2: {
			fontFamily: "Open Sans",
			fontWeight: 400,
			fontSize: 18,
			letterSpacing: 0.18,
			lineHeight: "29px"
		},
		overline: {
			fontFamily: "Open Sans",
			fontWeight: 400,
			fontSize: 14,
			letterSpacing: 0.7000000000000001,
			lineHeight: "23px"
		},
		"button-large": {
			fontFamily: "Sofia Sans",
			fontWeight: 600,
			fontSize: 20,
			letterSpacing: 0.2,
			textTransform: "uppercase",
			lineHeight: "20px"
		},
		"button-medium": {
			fontFamily: "Sofia Sans",
			fontWeight: 600,
			fontSize: 16,
			letterSpacing: 0.16,
			textTransform: "uppercase",
			lineHeight: "16px"
		},
		"button-small": {
			fontFamily: "Sofia Sans",
			fontWeight: 600,
			fontSize: 14,
			letterSpacing: 0.42,
			textTransform: "uppercase",
			lineHeight: "14px"
		}
	},
	shape: {
		borderRadius: 0
	},
	overrides: {
		MuiButton: {
			root: {
				boxShadow: "none !important",
				fontFamily: "'Sofia Sans' !important",
				fontSize: 16,
				fontWeight: 600,
				letterSpacing: "0.01em !important",
				minHeight: 48
			},
			sizeLarge: {
				fontSize: 20,
				letterSpacing: "0.01em !important",
				minHeight: 56
			},
			sizeSmall: {
				fontSize: 14,
				letterSpacing: "0.03em !important",
				minHeight: 40
			},
			outlinedPrimary: {
				border: "1px solid #836422 !important",
				color: "#836422 !important",
				"& svg path": {
					fill: "#836422 !important"
				}
			},
			textPrimary: {
				color: "#836422 !important",
				"& svg path": {
					fill: "#836422 !important"
				}
			},
			text: {
				"&:hover": {
					background: "none !important"
				},
				"& .MuiTouchRipple-root": {
					display: "none"
				}
			}
		},
		rootMuiInputBase: {
			root: {
				fontFamily: "'Open Sans' !important",
				fontSize: "16px !important"
			}
		},
		MuiFormLabel: {
			root: {
				fontFamily: "'Open Sans' !important",
				fontSize: "16px !important"
			}
		}
	},
	props: {
		MuiButton: {
			disableElevation: true
		},
		MuiButtonGroup: {
			disableElevation: true
		}
	}
};
