import React, { useState } from "react";
import loadable from "@loadable/component";
import clsx from "clsx";

import styles from "./Level1.styles";

const MenuItem = loadable(() => import("../MenuItem"));
const Level2 = loadable(() => import("../Level2"));

const Level1 = ({ items }) => {
	const css = styles();
	const [activeMenuID, setActiveMenuID] = useState();

	const handleMenuItemClick = (e, item) => {
		const { id, items } = item;
		if (!items) return;
		e.preventDefault();

		setActiveMenuID(id);
	};

	const handleClickAway = () => {
		setActiveMenuID(undefined);
	};

	return (
		<div className={clsx(css.level1, "mco-view-component-header-desktop-menu-level1")}>
			{items?.map(item => {
				const { id, items } = item;

				return (
					<div
						className={clsx(
							css.item,
							"mco-view-component-header-desktop-menu-level1__list-item"
						)}
						key={id}
					>
						<MenuItem
							item={item}
							level={1}
							onClick={handleMenuItemClick}
							selected={activeMenuID === id}
						/>
						{activeMenuID === id && <Level2 items={items} onClickAway={handleClickAway} />}
					</div>
				);
			})}
		</div>
	);
};

export default Level1;
