import React, { useContext } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import PageContext from "@PageContext";
import groupFollowingElementsByTag from "@micado-digital/react-ui/utils/groupFollowingElementsByTag";
import useSetting from "@micado-digital/react-ui/hooks/useSettings";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useSystemLinks from "@micado-digital/react-ui/hooks/useSystemLinks";
import getJSXElement from "./getJSXElement";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import localDE from "@localDE";
import localEN from "@localEN";
import localNL from "@localNL";

import styles from "./Elements.styles";

const Breadcrumb = loadable(() => import("@Components/views/Breadcrumb"));

const Elements = ({ parentElements }) => {
	const headerSettings = useSetting({
		identifier: "header",
		layout: true,
		layoutScroll: true,
		breakpoints: true
	});
	const { height: headerHeight } = headerSettings;
	const css = styles({ headerHeight });
	const [pageData] = useContext(PageContext);
	let { elements, homepage, lang } = pageData;
	const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
	const sysLink = useSystemLinks();
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN,
			nl: localNL
		}
	});

	let parsedElements = parentElements ? parentElements : elements;

	// group downloads

	parsedElements = groupFollowingElementsByTag(
		parsedElements,
		"basic-download-group",
		"basic-download"
	);

	// group dropdowns

	parsedElements = groupFollowingElementsByTag(
		parsedElements,
		"basic-dropdown-group",
		["basic-dropdown", "*"],
		"basic-dropdown",
		"basic-dropdown"
	);

	// exclude elements

	const excludeElementTags = ["basic-animation-textbox"];

	parsedElements = parsedElements.filter(element => {
		return excludeElementTags.indexOf(element.tag) === -1;
	});

	return (
		<div className={clsx(css.elements, "mco-elements")}>
			{!homepage && <Breadcrumb />}
			{parsedElements?.map(element => {
				return getJSXElement(element, lang, l, isMobile, sysLink);
			})}
		</div>
	);
};

export default Elements;
