import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	logo: {
		display: "flex",
		position: "relative",
		zIndex: 110
	},
	link: {
		display: "block"
	},
	image: {
		display: "block"
	}
}));

export default styles;
