import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	header: {
		height: 72,
		left: 0,
		position: "fixed",
		right: 0,
		top: 0,
		userSelect: "none",
		zIndex: theme.zIndex.appBar + 1,
		transition: "background 300ms ease",
		"&:before": {
			background: theme.palette.common.white,
			content: "''",
			display: "block",
			height: "100%",
			left: 0,
			position: "absolute",
			right: 0,
			top: 0,
			zIndex: 2
		}
	},
	headerScrolling: {
		paddingTop: 0,
		"& .mco-view-component-header-lang": {
			borderColor: theme.palette.primary.dark,
			color: theme.palette.primary.dark
		},
		"& .mco-view-component-header-desktop-menu-item-level1": {
			color: theme.palette.primary.dark + " !important"
		},
		"& .mco-view-component-header-desktop-menu-item--selected": {
			borderBottomColor: theme.palette.primary.dark + " !important"
		}
	},
	headerIntro: {
		[theme.breakpoints.up("md")]: {
			color: theme.palette.common.white,
			height: 136,
			"&:before": {
				background: "none",
				borderBottom: "1px solid " + theme.palette.grey[200],
				boxShadow: "none"
			}
		}
	},
	headerWithVoucher: {
		[theme.breakpoints.up("md")]: {
			height: 192 /* without voucher 136px */,
			paddingTop: 56
		}
	},
	headerDesktopMenuOpen: {
		color: theme.palette.text.primary,
		"&:before": {
			background: "none",
			borderBottom: "1px solid " + theme.palette.common.white,
			boxShadow: "none",
			left: "33.3333333%",
			width: "66.666666%"
		}
	},
	headerBreadcrumb: {
		"& + .mco-view-component-header-animation-wrapper .mco-view-component-header-cta": {
			top: 123,
			transition: "all 300ms ease-in 300ms"
		}
	},
	container: {
		alignItems: "center",
		display: "flex",
		gap: theme.spacing(5),
		height: "100%",
		position: "relative"
	},
	icons: {
		alignItems: "center",
		display: "flex",
		marginLeft: "auto",
		position: "relative",
		zIndex: 2,
		[theme.breakpoints.up("md")]: {
			marginLeft: 0
		},
		[theme.breakpoints.up("lg")]: {
			gap: theme.spacing(2),
			padding: theme.spacing(0, 0, 0, 5),
			"&:before": {
				background: theme.palette.grey[200],
				content: "''",
				display: "block",
				height: 48,
				left: 0,
				position: "absolute",
				top: "50%",
				transform: "translate(0, -50%)",
				width: 1,
				zIndex: 3
			}
		}
	}
}));

export default styles;
