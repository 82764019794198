import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	voucherBar: {
		alignItems: "center",
		background: theme.palette.background.dark,
		display: "none",
		height: 56,
		justifyContent: "center",
		left: 0,
		position: "absolute",
		top: 0,
		transform: "translate(0, -56px)",
		transition: "transform 800ms ease",
		width: "100%",
		zIndex: theme.zIndex.appBar + 2,
		[theme.breakpoints.up("md")]: {
			display: "flex"
		}
	},
	voucherVisible: {
		transform: "translate(0, 0)"
	},
	voucherBarScroll: {
		transform: "translate(0, -56px)"
	},
	close: {
		alignItems: "center",
		cursor: "pointer",
		display: "flex",
		position: "absolute",
		right: 12,
		height: 32,
		marginTop: -16,
		top: "50%",
		justifyContent: "center",
		width: 32
	},
	wrapper: {
		alignItems: "center",
		display: "flex",
		gap: theme.spacing(2)
	},
	labelDirectBooking: {
		color: "#FFFFFF",
		fontSize: 14,
		fontWeight: 700,
		letterSpacing: "0.05em"
	},
	discount: {
		alignItems: "center",
		background: theme.palette.secondary.dark,
		color: "#FFFFFF",
		fontSize: 20,
		fontWeight: 600,
		display: "flex",
		fontFamily: "Sofia Sans",
		height: 32,
		justifyContent: "center",
		padding: 6
	},
	addition: {
		color: "#FFFFFF",
		fontSize: 14,
		fontWeight: 400,
		letterSpacing: "0.05em"
	},
	codeWrapper: {
		alignItems: "center",
		display: "flex",
		[theme.breakpoints.up("md")]: {
			marginLeft: theme.spacing(3)
		}
	},
	code: {
		alignItems: "center",
		border: "1px solid " + theme.palette.primary.main,
		borderRight: "unset",
		color: "#FFFFFF",
		display: "flex",
		gap: theme.spacing(2),
		height: 40,
		padding: theme.spacing(0, 2),
		"& span": {
			color: "#FFFFFF",
			fontSize: 14,
			fontWeight: 400,
			letterSpacing: "0.05em"
		},
		"& strong": {
			fontFamily: "Sofia Sans",
			fontSize: 24
		}
	},
	button: {
		paddingLeft: "16px !important",
		paddingRight: "16px !important"
	}
}));

export default styles;
