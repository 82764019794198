import React, { useContext, useRef, useState, Suspense } from "react";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import { motion } from "framer-motion";
import loadable from "@loadable/component";
import UiContext from "@UiContext";
import PageContext from "@PageContext";
import useSetting from "@micado-digital/react-ui/hooks/useSettings";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useScrolling from "../../../hooks/useScrolling";
import ReactAnimation from "@micado-digital/react-animation/ReactAnimation";

import styles from "./Animation.styles";

const Typography = loadable(() => import("@micado-digital/react-ui/components/Typography"));
const ReactSVGIcon = loadable(() => import("@micado-digital/react-svg-icon/ReactSVGIcon"));
const ScrollBottom = loadable(() => import("../Header/ScrollBottom"));

const Animation = () => {
	const { REACT_APP_PATH } = process.env;
	const animationSettings = useSetting({
		identifier: "animation",
		layout: true
	});
	const headerSettings = useSetting({
		identifier: "header",
		layout: true,
		layoutScroll: true,
		breakpoints: true
	});
	const [ui] = useContext(UiContext);
	const { mobileMenuOpen } = ui;
	const { height: headerHeight } = headerSettings;
	const { mediaFormats } = animationSettings;
	const [pageData] = useContext(PageContext);
	const { animationID, lang, layoutVariant } = pageData;
	const [animationTexts, setAnimationTexts] = useState([]);
	const $wrapper = useRef(null);
	const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
	const scrollPosition = useScrolling();
	const isScrollingTop = scrollPosition > 0;
	const css = styles({ headerHeight });

	const isIntro = layoutVariant === "intro";

	const handleSlideChange = animationSlide => {
		const { texts } = animationSlide || {};

		if (!texts) {
			setAnimationTexts([]);
			return;
		}

		const rawTexts = texts?.map(text => text.text);
		setAnimationTexts(rawTexts);
	};

	if (isEmpty(pageData)) return null;

	return (
		<div
			className={clsx(css.animationWrapper, "mco-view-component-header-animation-wrapper", {
				[css.animationWrapperIntro]: isIntro,
				"mco-view-component-header-animation-wrapper--intro": isIntro
			})}
			ref={$wrapper}
		>
			<div
				className={clsx(css.animation, "mco-view-component-animation", {
					[css.animationScrolling]: isScrollingTop,
					"mco-view-component-animation--scrolling": isScrollingTop,
					[css.animationMobileMenuOpen]: mobileMenuOpen,
					"mco-view-component-animation--mobile-menu-open": mobileMenuOpen,
					[css.animationIntro]: layoutVariant === "intro",
					"mco-view-component-animation--intro": layoutVariant === "intro",
					[css.animationIntroMobile]: isIntro && isMobile
				})}
			>
				<Suspense fallback={<></>}>
					<ReactAnimation
						api={`${REACT_APP_PATH}/animationV3.json.api`}
						hideTexts={isIntro}
						id={animationID}
						lang={lang}
						mediaFormats={mediaFormats}
						onSlideChange={handleSlideChange}
						prevIcon={
							<ReactSVGIcon
								src={
									isMobile ? "/img/icons/chevron-left.svg" : "/img/icons/chevron-left-48.svg"
								}
								size={isMobile ? 24 : 64}
							/>
						}
						nextIcon={
							<ReactSVGIcon
								src={
									isMobile ? "/img/icons/chevron-right.svg" : "/img/icons/chevron-right-48.svg"
								}
								size={isMobile ? 24 : 64}
							/>
						}
						showArrows
						showBullets
						textComponents={{
							text1: "p",
							text2: "p"
						}}
						textVariants={{
							text1: {
								xs: "display-small",
								md: "display-medium",
								lg: "display-large"
							},
							text2: {
								xs: "title-medium"
							}
						}}
						webpFallback
					/>
				</Suspense>
			</div>
			<ScrollBottom lang={lang} />
			{isIntro && (
				<>
					<div
						className={clsx(css.animationTexts, "mco-view-component-header-animation-texts", {
							[css.animationIntroMobileTexts]: isIntro && isMobile,
							"mco-view-component-header-animation-intro-mobile-texts": isIntro && isMobile
						})}
					>
						<div
							className={clsx(
								css.animatioTextsContent,
								"mco-view-component-header-animation-texts__content",
								{
									[css.animationIntroMobileTextsContent]: isIntro && isMobile,
									"mco-view-component-header-animation-intro-texts__content":
										isIntro && isMobile
								}
							)}
						>
							{animationTexts?.map((text, counter) => {
								let variants = {};
								if (counter === 0) {
									variants = {
										xs: "display-small",
										md: "display-medium",
										lg: "display-large"
									};
								} else {
									variants = {
										xs: "title-medium"
									};
								}

								return (
									<motion.div
										animate={{ x: 0, opacity: 1 }}
										initial={{ x: -40, opacity: 0 }}
										key={text}
										transition={{
											duration: 0.8,
											delay: (counter + 1) * 0.1 + 1,
											stiffness: 50,
											type: "spring"
										}}
									>
										<Typography
											className={`text-${counter}`}
											dangerouslySetInnerHTML={{ __html: text }}
											variants={variants}
										/>
									</motion.div>
								);
							})}
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default Animation;
