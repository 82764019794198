import React, { useContext } from "react";
import loadable from "@loadable/component";
import clsx from "clsx";
import UiContext from "@UiContext";

import styles from "./Hamburger.styles";

const ReactSVGIcon = loadable(() => import("@micado-digital/react-svg-icon/ReactSVGIcon"));

const Hamburger = () => {
	const css = styles();
	const [ui, setUi] = useContext(UiContext);
	const { mobileMenuOpen } = ui;

	const handleClick = () => {
		setUi(prevUi => ({ ...prevUi, mobileMenuOpen: !mobileMenuOpen }));
	};

	return (
		<div
			className={clsx(css.hamburger, "mco-view-component-header-hamburger")}
			onClick={handleClick}
		>
			<ReactSVGIcon
				color="initial"
				src={"/img/icons/" + (mobileMenuOpen ? "menu-close" : "menu") + ".svg"}
				size={24}
			/>
		</div>
	);
};

export default Hamburger;
