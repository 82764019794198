import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	page: {
		display: "flex",
		flexDirection: "column",
		minHeight: "100vh",
		overflow: "clip",
		padding: "72px 0 0 0"
	},
	pageIntro: {
		[theme.breakpoints.up("md")]: {
			paddingTop: 0
		}
	}
}));

export default styles;
