import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	desktopMenu: {
		marginLeft: "auto",
		position: "relative",
		userSelect: "none",
		zIndex: 150
	}
}));

export default styles;
