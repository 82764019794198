import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import UiContext from "@UiContext";
import PageContext from "@PageContext";
import useSystemLogo from "@micado-digital/react-ui/hooks/useSystemLogo";
import useScrolling from "../../../../hooks/useScrolling";
import ReactLink from "@micado-digital/react-link/ReactLink";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { motion } from "framer-motion";

import styles from "./Logo.styles";

const Logo = () => {
	const css = styles();
	const [ui] = useContext(UiContext);
	const { desktopMenuOpen } = ui;
	const [pageData] = useContext(PageContext);
	const { layoutVariant } = pageData;
	const logoObjMobile = useSystemLogo("logo-mobile");
	const logoObjDefault = useSystemLogo("logo");
	const logoObjIntro = useSystemLogo("logo-intro");
	const [logoObj, setLogoObj] = useState({});
	const [height, setHeight] = useState(40);
	const [width, setWidth] = useState(296);
	const { alt, homeLink, src } = logoObj;
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const scrollPosition = useScrolling();
	const isScrollingTop = scrollPosition > 0;

	useEffect(() => {
		if (isMobile) {
			setLogoObj(logoObjMobile);
			setHeight(60);
			setWidth(60);
		} else if (layoutVariant === "intro" && !isMobile && !isScrollingTop) {
			setHeight(88);
			setWidth(180);
			setLogoObj(logoObjIntro);
		} else {
			setHeight(40);
			setWidth(296);
			setLogoObj(logoObjDefault);
		}
	}, [isMobile, isScrollingTop, layoutVariant, logoObjDefault, logoObjIntro, logoObjMobile]);

	if (desktopMenuOpen) return null;

	return (
		<div className={clsx(css.logo, "mco-view-component-header-logo")}>
			<ReactLink
				className={clsx(css.link, "mco-view-component-header-logo__link")}
				to={homeLink}
			>
				<motion.img
					alt={alt}
					className={clsx(css.image, "mco-view-component-header-logo__image")}
					height={height}
					src={src}
					width={width}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 0.5, delay: 0.1 }}
					key={src}
				/>
			</ReactLink>
		</div>
	);
};

export default Logo;
