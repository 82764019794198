import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	hamburger: {
		alignItems: "center",
		cursor: "pointer",
		display: "flex",
		justifyContent: "center",
		height: 48,
		marginRight: -16,
		width: 48
	}
}));

export default styles;
