import makeStyles from "@material-ui/styles/makeStyles";

const styles = () => {
	return makeStyles(theme => ({
		mobilemenuWrapper: {
			background: theme.palette.background.menu,
			position: "fixed",
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			zIndex: theme.zIndex.modal - 2
		},
		mobilemenuWrapperOpen: {},
		mobilemenu: {
			height: "100%",
			position: "relative",
			"& .mco-view-component-header-booking": {
				left: 24,
				bottom: 24,
				position: "absolute",
				right: 88,
				"& button": {
					maxWidth: 300,
					width: "100%"
				}
			}
		},
		list: {
			display: "flex",
			flexDirection: "column",
			height: "100%",
			margin: "0 auto",
			overflow: "auto",
			padding: theme.spacing(0, 3, 12.5),
			paddingTop: theme.spacing(10),
			position: "relative",
			top: 0
		},
		listHasSubmenu: {
			top: 0
		}
	}))();
};

export default styles;
