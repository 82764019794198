import makeStyles from "@material-ui/styles/makeStyles";

const styles = ({ headerHeight }) => {
	return makeStyles(theme => ({
		elements: {
			display: "flex",
			flexDirection: "column",
			gap: theme.spacing(10),
			scrollMarginTop: "72px",
			paddingBottom: theme.spacing(10),
			position: "relative",
			zIndex: 1,
			"& > *:first-child:not(.mco-collapsing)": {
				marginTop: theme.spacing(4)
			},
			"& > *:last-child:not(.mco-collapsing)": {
				marginBottom: theme.spacing(7)
			},
			[theme.breakpoints.up("md")]: {
				"& > *:first-child:not(.mco-collapsing)": {
					marginTop: theme.spacing(10)
				},
				"& > *:last-child:not(.mco-collapsing)": {
					marginBottom: theme.spacing(10)
				}
			}
		}
	}))();
};

export default styles;
