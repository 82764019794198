import React, { Suspense, useContext } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import UiContext from "@UiContext";
import WebProfileContext from "@WebProfileContext";
import PageContext from "@PageContext";

import styles from "./Lang.styles";

const ReactLangSelection = loadable(() =>
	import("@micado-digital/react-lang-selection/ReactLangSelection")
);
const Typography = loadable(() => import("@micado-digital/react-ui/components/Typography"));

const Lang = () => {
	const css = styles();
	const [ui, setUi] = useContext(UiContext);
	const { langSelectOpen } = ui;
	const [profileData] = useContext(WebProfileContext);
	const { languages } = profileData;
	const [pageData] = useContext(PageContext);
	const { lang, meta: { links } = {} } = pageData;

	const handleClick = () => {
		setUi(prevUi => ({ ...prevUi, langSelectOpen: true }));
	};

	const handleClose = () => {
		setUi(prevUi => ({ ...prevUi, langSelectOpen: false }));
	};

	return (
		<>
			<div className={clsx(css.lang, "mco-view-component-header-lang")} onClick={handleClick}>
				<Typography variant="label-small">{lang}</Typography>
			</div>
			<Suspense fallback={<></>}>
				{langSelectOpen && (
					<ReactLangSelection
						currentLanguage={lang}
						languages={languages}
						links={links}
						setOverlay={handleClose}
					/>
				)}
			</Suspense>
		</>
	);
};

export default Lang;
