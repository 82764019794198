import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	level1: {
		alignItems: "center",
		display: "flex",
		gap: theme.spacing(2.5),
		height: "100%",
		[theme.breakpoints.up("1000")]: {
			gap: theme.spacing(3)
		},
		[theme.breakpoints.up("lg")]: {
			gap: theme.spacing(5)
		}
	},
	item: {
		alignItems: "center",
		display: "flex",
		height: "100%"
	}
}));

export default styles;
