import makeStyles from "@material-ui/styles/makeStyles";

const styles = (background, maxWidth, noPadding) => {
	return makeStyles(theme => ({
		container: {
			background: background ? theme.palette.background.light : "none",
			margin: "0 auto",
			maxWidth: maxWidth,
			paddingLeft: noPadding ? 0 : theme.spacing(2),
			paddingRight: noPadding ? 0 : theme.spacing(2),
			paddingBottom: background ? theme.spacing(10) : 0,
			paddingTop: background ? theme.spacing(10) : 0,
			width: "100%",
			"&[data-anchor]": {
				scrollMarginTop: "140px"
			},
			[theme.breakpoints.up("md")]: {
				paddingLeft: noPadding ? 0 : theme.spacing(3),
				paddingRight: noPadding ? 0 : theme.spacing(3)
			}
		}
	}))();
};

export default styles;
